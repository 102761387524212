div.container a.input-group-btn {
    font-size: 14px;
}

.suggestions {
    position: relative;
    display: inline-block;
    width: inherit;
    z-index:99
}

.input-group {
    flex-wrap: nowrap;
}