.result {
    width: 250px;
    padding: 16px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.result:hover,
.result:active {
    background-color: #C0DDF5;
}

.title-style {
    vertical-align: middle;
}

.card-img-top {
    width: auto;
    min-width: 6em;
    max-width: 10em;
    height: auto;
    max-height: 12em;
    vertical-align: middle;
}

.tag{
    display:flex;
    align-items: center
}
.tag1{
    font-size: 12px;
    background: #ffbf69;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}

.tag2{
    font-size: 12px;
    background: #99d98c;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color:black;
}

.styleticket{
    font-size: 12px;
    background: #80ed99;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}
.stylekbinternal{
    font-size: 12px;
    background: #a3cef1;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}
.stylekbexternal{
    font-size: 12px;
    background: #e0aaff;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}

.styleBizagiDevs{
    font-size: 12px;
    background: #a3c2c2;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}

.styleBizagiXPress{
    font-size: 12px;
    background: #acc196;
    padding: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    opacity: 0.8;
    color: black;
}

.resultBox {
    margin-top: 15px;
    background: #9e9e9e1a;
    padding: 10px;
}
