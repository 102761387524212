.facetbox {
    border-right: 1px solid #f0f0f0;
}

.box {
    height: 100%;
}

.listitem {
    padding-left: 36px !important;
}

.filterlist {
    list-style: none;
}

.chip {
    margin: 0.25em;
}

.sources{
    margin-top: 20px;
}

.sources-title {
    font-size: 1.1rem;
    margin: 20px;
    font-weight: bold;
}

.panel-card {
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,.06),0 2px 2px rgba(0,0,0,.12);
    padding: 1rem 1.4rem;
    min-width: 185px;
    width: 100%;
    transition-duration: .2s;
}

.bz-space--stack-l
{
    margin-bottom: 1rem;
}

.panel-card-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.panel-card-header a {
    text-decoration: underline;
    color: #217d7e;
}

.panel-card-header--logo {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    margin-right: 1.6rem;
    border-radius: 4px;
    background: 0 0;
    -o-object-fit: none;
    object-fit: none;
    overflow: hidden;
    box-shadow: 0 0 4px rgba(0,0,0,.06),0 2px 2px rgba(0,0,0,.12);
}

.panel-card-header--logo.modeler {
    background: #f08b1d;
}

.panel-card-header--logo.studio {
    background: #92c82d;
}

.panel-card-header--logo.automation {
    background: #33bfc1;
}

.panel-card-header-text--title {
    display: block;
}

.bz-font-size--body-small {
    font-size: 0.9rem;
}

.margin15{
    margin-bottom: 15px;
}