/* Application styles */
.app {
    background-color: #fff;
    padding: 0;
    min-width: 375px;
}

.main--home {
    min-height: 40em;
}
.main--details {
    min-height: 40em;
}
